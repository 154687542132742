<template>
    <div class="container mt-2">
        <v-img
            class="content"
            max-width="80%"
            :src="require('@/assets/secretd.png')"
        ></v-img>
    </div>
    <div style="height: 150vh">

    </div>
    <div class="pa-6 text-center">
        <p class="mb-4">
            When snow falls through a beam of light, it glows under the dark sky like thousands of fleetings stars. This phenomenon is called luminos, colloquially referred to as "snowlights".
        </p>
        <p>
            It makes the world beautiful.
        </p>
    </div>
</template>

<script>

export default {
    name: "SecretD"
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    max-width: 60%;
}

</style>