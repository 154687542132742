<template>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
          <v-img
            :src="require('@/assets/icons/play.svg')"
            max-width="80px"
            contain
            @click="downloadAudio"
            class="pt-8 clickable"
          ></v-img>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: 'SecretC',
  components: {
  },
  methods: {
    downloadAudio() {
      /*
      fetch('https://marginofthestrange.com/audio/keys.mp3')
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'keys.mp3';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error(error);
        });
      */

      const file = "https://marginofthestrange.com/audio/keys.mp3";
      const link = document.createElement('a');
      link.href = file;
      link.download = 'keys.mp3';
      link.click();
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.clickable:hover {
  transform: translate3d(0px, -2px, 0px);
}
</style>