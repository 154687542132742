<template>
    <center>
        <v-img
            class="pl-10 pr-10"
            max-width="60%"
            :src="require('@/assets/Ling.png')"
        ></v-img>
    </center>
</template>

<script>

export default {
    name: "SecretA"
}
</script>
